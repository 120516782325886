import { GrantSubmissionStatus } from '@app/shared/enums';
import {
  ApiResponse,
  FparSubmissionComment,
  FparTable,
  FparTableOverview,
  ReportingPeriodState,
} from '@core/models';

export interface Grant {
  grantId: number;
  granteeName: string;
  grFparNumber: string;
  grState: string;
  hasUploaded?: boolean;
  preferredApproach?: boolean;
}

export interface GrantRequestPayload {
  grantId: string;
  subrecipientId?: string;
}
export interface GrantSubmission<T = FparTable> extends Grant {
  dataQualityChecksRan: boolean;
  fparTables: T[];
  reportingPeriod: ReportingPeriodState;
  reviewStatus: GrantSubmissionStatus;
  submissionComment: FparSubmissionComment;
  submissionDate: Date;
  systemForcedStatusChange: boolean;
  validationWarningsExist: boolean;
}

export interface GrantSubmissionTableReview {
  name: string;
  reviewDate: Date;
  role: string;
  tableId: number;
}

export interface GrantSubmissionTableOverview extends GrantSubmission {
  lastTableReviewed: GrantSubmissionTableReview;
  fparTableOverviews: FparTableOverview[];
}

export type GrantApiResponse = ApiResponse<Grant>;
export type GrantListApiResponse = ApiResponse<Grant[]>;
export type GrantSubmissionModifyPayload = Pick<
  GrantSubmission,
  'reviewStatus'
>;
export type GrantSubmissionListApiResponse = ApiResponse<GrantSubmission[]>;
export type GrantSubmissionsTableOverviewApiResponse =
  ApiResponse<GrantSubmissionTableOverview>;

export type FavoriteGrantsApiResponse = ApiResponse<number[]>;
export type FavoriteGrantsPayload = {
  grantIds: number[];
};
