import { ColumnMapping } from './file-upload-task.interface';

export interface GrantFieldMapping {
  [key: number]: string;
}

export interface GrantFieldMappingForm extends ColumnMapping {
  missingAllowed: boolean;
  systemColumnName: string;
  validateCode: boolean;
  validateDate: boolean;
  validCodes?: string[];
}

export interface GrantFieldMappingFormData {
  [key: string]: GrantFieldMappingForm;
}
