import { FparIssueGroup } from '.';
import { ApiResponse } from './api-response.interface';

export interface DataQualityResults {
  clean: number[];
  completedLog: DataQualityCheckCompletedLog[];
  issues: FparIssueGroup[];
}

export interface DataQualityCheckCompletedLog {
  checkStartDate: Date;
  checkEndDate: Date;
}

export type DataQualityResultsApiResponse = ApiResponse<DataQualityResults>;
