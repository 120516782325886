import { SiteType, YesNo } from '@app/shared/enums';
import { ApiResponse } from './api-response.interface';
import { PresignedPost } from './file-upload-task.interface';
import { ServicesOffered } from './services.interface';

export interface AddSitePayload {
  admin?: boolean | null;
  amendmentNumber: string;
  name: string;
  noaFileName: string;
  number340B: string;
  organizationType: SiteType;
  primaryServiceSiteType: string;
  relatedPrograms: RelatedProgram[];
  secondaryServiceSiteType: string;
  subrecipientId?: number;
  timestamp: number;
  url: string;
}

export interface AddSiteResponse extends RemoveSiteResponse {
  clinicId: string;
}

export interface HoursOfOperation {
  closedAllDay: boolean;
  closeTime: string;
  dayOfWeek: string;
  notes: string;
  openTime: string;
}

export interface NoaFile {
  noaFile: File;
}

export interface RelatedProgram {
  programName: string;
  value: boolean;
}

export interface RemoveSitePayload {
  active: boolean;
  amendmentNumber: string;
  noaFileName: string;
  notes: string;
  timestamp: number;
}

export interface RemoveSiteResponse {
  noaS3Key: string;
  presignedPost: PresignedPost;
}

export interface Site {
  active: YesNo;
  city: string;
  entityId: string;
  lastUpdated: Date;
  name: string;
  parentSubrecipientId: string;
  siteType: string;
  state: string;
  visible: YesNo;
}

export interface SiteDetails
  extends SiteLocationPayload,
    SiteHoursOfOperationPayload {
  active: boolean;
  admin: boolean;
  createdTime: Date;
  granteeName: string;
  id: string;
  name: string;
  organizationType: string;
  primaryServiceSiteType: string;
  providesServices: boolean;
  published: boolean;
  relatedPrograms: string[];
  secondaryServiceSiteType: string;
  servicesOffered: ServicesOffered;
  status: string;
  subrecipientName: string;
  url: string;
}

export interface SiteHoursOfOperationPayload {
  facilityInformation: string;
  hoursOfOperation: HoursOfOperation[];
  hoursUnknown: false;
}

export interface SiteLocationPayload {
  addressMailingCity: string;
  addressMailingState: string;
  addressMailingStreet1: string;
  addressMailingStreet2: string;
  addressMailingZip: string;
  addressPhysicalCity: string;
  addressPhysicalState: string;
  addressPhysicalStreet1: string;
  addressPhysicalStreet2: string;
  addressPhysicalZip: string;
  phone: string;
  specialFacilityAccess: SpecialFacilityAccessItem[];
}

export interface SpecialFacilityAccessItem {
  accessType: string;
  value: boolean;
}

export type AddSiteApiResponse = ApiResponse<AddSiteResponse>;
export type RemoveSiteApiResponse = ApiResponse<RemoveSiteResponse>;
export type SiteApiResponse = ApiResponse<Site[]>;
export type SiteDetailsApiResponse = ApiResponse<SiteDetails>;
