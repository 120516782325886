import { AccountState } from './account';
import { AuthenticationState } from './authentication';
import { SiteState } from './clinic-locator';
import { ClinicStepperState } from './clinic-stepper';
import { DashboardState } from './dashboard';
import { FparReportState } from './fpar-report';
import { FparTableState } from './fpar-table';
import { GrantState } from './grant';
import { GrantProfileCoverSheetState } from './grant-profile-cover-sheet';
import { MenuState } from './menu';
import { NotificationState } from './notification';
import { PortalReviewState } from './portal-review';
import { PortalStepperState } from './portal-stepper';
import { ReportingPeriodState } from './reporting-period';
import { ResourceAccessState } from './resource-access';
import { SettingsState } from './settings';
import { TableArchiveState } from './table-archive';
import { UserState } from './user';
import { UserAppState } from './user-app';

/** Add Feature module interfaces here */
export interface State {
  account: AccountState.State;
  authentication: AuthenticationState.State;
  clinicStepper: ClinicStepperState.State;
  dashboard: DashboardState.State;
  fparReport: FparReportState.State;
  fparTable: FparTableState.State;
  grant: GrantState.State;
  grantProfileCoverSheet: GrantProfileCoverSheetState.State;
  menu: MenuState.State;
  notification: NotificationState.State;
  portalReview: PortalReviewState.State;
  portalStepper: PortalStepperState.State;
  reportingPeriod: ReportingPeriodState.State;
  resourceAccess: ResourceAccessState.State;
  sites: SiteState.State;
  settings: SettingsState.State;
  tableArchive: TableArchiveState.State;
  user: UserState.State;
  userApp: UserAppState.State;
}
