import { FileUploadTypes } from '@app/shared/enums';

export interface GrantFileUploadData {
  data: unknown[];
  fieldNames: string[];
  file: File;
  fileName: string;
  parseErrors: Papa.ParseError[];
}

export interface GrantFileUpload {
  combined: GrantFileUploadData;
  encounters: GrantFileUploadData;
  labs: GrantFileUploadData;
}

export interface GrantFileUploadResults {
  results: Papa.ParseResult<unknown>;
  type: FileUploadTypes;
  file: File;
}

export interface GrantFileUploadStatus {
  type: string;
  finished: boolean;
}
