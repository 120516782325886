import { TableSubmissionStatus } from '@app/shared/enums';
import { ApiResponse } from './api-response.interface';
import { GrantSubmission } from './grant.interface';

export interface GrantSubmissionTableStatus {
  tableId: number;
  tableName: string;
  tableTitle: string;
  status: TableSubmissionStatus;
  locked: boolean;
  issueCount: number;
}

export type GrantSubmissionTableOverviewApiResponse = ApiResponse<
  GrantSubmission<GrantSubmissionTableStatus>[]
>;
