import { ApiResponse } from './api-response.interface';

export interface ClinicServices {
  serviceCategory: string;
  services: Service[];
}

export interface Service {
  id: number;
  service: string;
}

export type ClinicServicesApiResponse = ApiResponse<ClinicServices[]>;
