import { ApiResponse } from '@core/models';

export interface GrantCoverSheetContactPerson {
  grantId?: number;
  grContactFirst: string;
  grContactLast: string;
  grContactMi: string;
  grContactSuffix: string;
  grContactTitle: string;
  grContactStAddr: string;
  grContactCity: string;
  grContactState: string;
  grContactZipCode: string;
  grContactPhoneNum: string;
  grContactFaxNum: string;
  grContactEmail: string;
}

export type GrantCoverSheetContactPersonApiResponse = ApiResponse<
  GrantCoverSheetContactPerson[]
>;
