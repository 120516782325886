import { ProviderToken } from '@angular/core';
import { Resource } from '@shared/enums';

export interface ColumnAction {
  action?: (arg: any) => void;
  icon?: string;
  label: string;
}

export interface TableColumnBase {
  headerName?: string;
  range?: string[];
}

export interface TableColumn extends TableColumnBase {
  column: string;
  columnAction?: ColumnAction;
  disableSort?: boolean;
  icon?: string | ((arg: any) => string);
  iconColor?: string | ((arg: any) => string);
  isHtml?: boolean;
  isDate?: boolean;
  maxWidth?: string;
  minWidth?: string;
  permissions?: Resource[];
  pipe?: ProviderToken<any>;
  pipeArgs?: any | any[];
  pipeElement?: boolean; // default is to pipe element value vs row element
  width?: string;
  headerTooltip?: string;
  headerIcon?: string | ((arg: any) => string);
  headerIconTooltip?: string;
}

export interface FparTableColumn extends TableColumn {
  alternates?: TableColumnBase[];
  customTotal?: string[];
  hideColumnTotal?: boolean;
  isId?: boolean;
  isLabel?: boolean;
  isTotal?: boolean;
}
