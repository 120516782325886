import { ApiResponse } from './api-response.interface';

export interface AccountBase {
  nameFirst: string;
  nameLast: string;
}

export interface Account extends AccountBase {
  cognitoId?: string;
  email: string;
  isAdmin: boolean;
  roleName: string;
}

export type AccountApiResponse = ApiResponse<Account[]>;
export type AccountUpdatePayload = AccountBase;
