import { HttpErrorResponse } from '@angular/common/http';
import {
  ApiError,
  ApiResponse,
  CustomReportingPeriodApiResponse,
  CustomReportingPeriodPayload,
  FavoriteGrantsApiResponse,
  FavoriteGrantsPayload,
  FileUploadResultApiResponse,
  FileUploadTaskApiResponse,
  FileUploadTaskPayload,
  FparDataColumnsApiResponse,
  FparSubmissionCommentApiResponse,
  GrantApiResponse,
  GrantFileUploadData,
  GrantFileUploadStatus,
  GrantListApiResponse,
  GrantNumberOfSites,
  GrantNumberOfSitesApiResponse,
  GrantRequestPayload,
  GrantSubmissionListApiResponse,
  GrantSubmissionModifyPayload,
  GrantSubmissionsTableOverviewApiResponse,
  GrantSubmissionTableOverviewApiResponse,
  SubrecipientApiResponse,
  UserGrantApiResponse,
  UserGrantSelection,
} from '@core/models';
import { createAction, props } from '@ngrx/store';

export enum GrantActionTypes {
  ACCEPT_GRANT_SUBMISSION_FAILURE = '[Grant] Update Grant Submission Failure',
  ACCEPT_GRANT_SUBMISSION_REQUEST = '[Grant] Update Grant Submission Request',
  ACCEPT_GRANT_SUBMISSION_SUCCESS = '[Grant] Update Grant Submission Success',
  ACCEPT_FILE_UPLOAD_FAILURE = '[Grant] Accept File Upload Failure',
  ACCEPT_FILE_UPLOAD_REQUEST = '[Grant] Accept File Upload Request',
  ACCEPT_FILE_UPLOAD_SUCCESS = '[Grant] Accept File Upload Success',
  CLEAR_FILE_UPLOAD_DATA = '[Grant] Clear File Upload Data',
  DELETE_CUSTOM_REPORTING_PERIOD_FAILURE = '[Grant] Delete Custom Reporting Period Failure',
  DELETE_CUSTOM_REPORTING_PERIOD_REQUEST = '[Grant] Delete Custom Reporting Period Request',
  DELETE_CUSTOM_REPORTING_PERIOD_SUCCESS = '[Grant] Delete Custom Reporting Period Success',
  DELETE_SUBMISSION_COMMENT_FAILURE = '[Grant] Delete Submission Comment Failure',
  DELETE_SUBMISSION_COMMENT_REQUEST = '[Grant] Delete Submission Comment Request',
  DELETE_SUBMISSION_COMMENT_SUCCESS = '[Grant] Delete Submission Comment Success',
  FILE_UPLOAD_TASK_FAILURE = '[Grant] File Upload Task Failure',
  FILE_UPLOAD_TASK_REQUEST = '[Grant] File Upload Task Request',
  FILE_UPLOAD_TASK_SUCCESS = '[Grant] File Upload Task Success',
  GET_DATA_COLUMNS_FAILURE = '[Grant] Get Data Columns Failure',
  GET_DATA_COLUMNS_REQUEST = '[Grant] Get Data Columns Request',
  GET_DATA_COLUMNS_SUCCESS = '[Grant] Get Data Columns Success',
  GET_FAVORITE_GRANTS_FAILURE = '[Grant] Get Favorite Grants Failure',
  GET_FAVORITE_GRANTS_REQUEST = '[Grant] Get Favorite Grants Request',
  GET_FAVORITE_GRANTS_SUCCESS = '[Grant] Get Favorite Grants Success',
  GET_FILE_UPLOAD_RESULTS_FAILURE = '[Grant] Get File Upload Results Failure',
  GET_FILE_UPLOAD_RESULTS_REQUEST = '[Grant] Get File Upload Results Request',
  GET_FILE_UPLOAD_RESULTS_SUCCESS = '[Grant] Get File Upload Results Success',
  GET_GRANT_FAILURE = '[Grant] Get Grant Failure',
  GET_GRANT_REQUEST = '[Grant] Get Grant Request',
  GET_GRANT_SUCCESS = '[Grant] Get Grant Success',
  GET_GRANT_DETAILS_FAILURE = '[Grant] Get Grant Details Failure',
  GET_GRANT_DETAILS_REQUEST = '[Grant] Get Grant Details Request',
  GET_GRANT_DETAILS_SUCCESS = '[Grant] Get Grant Details Success',
  GET_GRANT_DETAILS_SILENT_FAILURE = '[Grant] Get Grant Details (Silent) Failure',
  GET_GRANT_DETAILS_SILENT_REQUEST = '[Grant] Get Grant Details (Silent) Request',
  GET_GRANT_DETAILS_SILENT_SUCCESS = '[Grant] Get Grant Details (Silent) Success',
  GET_GRANT_LIST_FAILURE = '[Grant] Get Grant List Failure',
  GET_GRANT_LIST_REQUEST = '[Grant] Get Grant List Request',
  GET_GRANT_LIST_SUCCESS = '[Grant] Get Grant List Success',
  GET_GRANT_LIST_BY_USER_FAILURE = '[Grant] Get Grant List By User Failure',
  GET_GRANT_LIST_BY_USER_REQUEST = '[Grant] Get Grant List By User Request',
  GET_GRANT_LIST_BY_USER_SUCCESS = '[Grant] Get Grant List By User Success',
  GET_GRANT_SUBMISSIONS_LIST_FAILURE = '[Grant] Get Grant Submissions List Failure',
  GET_GRANT_SUBMISSIONS_LIST_REQUEST = '[Grant] Get Grant Submissions List Request',
  GET_GRANT_SUBMISSIONS_LIST_SUCCESS = '[Grant] Get Grant Submissions List Success',
  GET_GRANT_SUBMISSION_TABLE_OVERVIEW_FAILURE = '[Grant] Get Grant Submission Table Overview Failure',
  GET_GRANT_SUBMISSION_TABLE_OVERVIEW_REQUEST = '[Grant] Get Grant Submission Table Overview Request',
  GET_GRANT_SUBMISSION_TABLE_OVERVIEW_SUCCESS = '[Grant] Get Grant Submission Table Overview Success',
  GET_NUMBER_OF_SITES_FAILURE = '[Grant] Get Number Of Sites Failure',
  GET_NUMBER_OF_SITES_REQUEST = '[Grant] Get Number Of Sites Request',
  GET_NUMBER_OF_SITES_SUCCESS = '[Grant] Get Number Of Sites Success',
  GET_CUSTOM_REPORTING_PERIOD_FAILURE = '[Grant] Get Custom Reporting Period Failure',
  GET_CUSTOM_REPORTING_PERIOD_REQUEST = '[Grant] Get Custom Reporting Period Request',
  GET_CUSTOM_REPORTING_PERIOD_SUCCESS = '[Grant] Get Custom Reporting Period Success',
  GET_SELECTED_GRANT_FAILURE = '[Grant] Get Selected Grant Failure',
  GET_SELECTED_GRANT_REQUEST = '[Grant] Get Selected Grant Request',
  GET_SELECTED_GRANT_SUCCESS = '[Grant] Get Selected Grant Success',
  GET_SUBMISSION_COMMENT_FAILURE = '[Grant] Get Submission Comment Failure',
  GET_SUBMISSION_COMMENT_REQUEST = '[Grant] Get Submission Comment Request',
  GET_SUBMISSION_COMMENT_SUCCESS = '[Grant] Get Submission Comment Success',
  GET_SUBRECIPIENTS_FAILURE = '[Grant] Get Subrecipients Failure',
  GET_SUBRECIPIENTS_REQUEST = '[Grant] Get Subrecipients Request',
  GET_SUBRECIPIENTS_SUCCESS = '[Grant] Get Subrecipients Success',
  GET_TABLE_OVERVIEW_FAILURE = '[Grant] Get Grant Table Overview Failure',
  GET_TABLE_OVERVIEW_REQUEST = '[Grant] Get Grant Table Overview Request',
  GET_TABLE_OVERVIEW_SUCCESS = '[Grant] Get Grant Table Overview Success',
  POST_FILES_TO_S3_FAILURE = '[Grant] Post Files To S3 Failure',
  POST_FILES_TO_S3_REQUEST = '[Grant] Post Files To S3 Request',
  POST_FILES_TO_S3_SUCCESS = '[Grant] Post Files To S3 Success',
  RESET_FILE_UPLOAD_STATUS_REQUEST = '[Grant] Reset File Upload Status Request',
  RETURN_GRANT_SUBMISSION_FAILURE = '[Grant] Return Grant Submission Failure',
  RETURN_GRANT_SUBMISSION_REQUEST = '[Grant] Return Grant Submission Request',
  RETURN_GRANT_SUBMISSION_SUCCESS = '[Grant] Return Grant Submission Success',
  SET_COMBINED_UPLOAD_REQUEST = '[Grant] Set Combined Upload Request',
  SET_ENCOUNTER_UPLOAD_REQUEST = '[Grant] Set Encounter Upload Request',
  SET_FILE_UPLOAD_STATUS_REQUEST = '[Grant] Set File Upload Status Request',
  SET_LABS_UPLOAD_REQUEST = '[Grant] Set Labs Upload Request',
  SET_SELECTED_GRANT_FAILURE = '[Grant] Set Selected Grant Failure',
  SET_SELECTED_GRANT_REQUEST = '[Grant] Set Selected Grant Request',
  SET_SELECTED_GRANT_SUCCESS = '[Grant] Set Selected Grant Success',
  SUBMIT_GRANT_FAILURE = '[Grant] Submit Grant Failure',
  SUBMIT_GRANT_REQUEST = '[Grant] Submit Grant Request',
  SUBMIT_GRANT_SUCCESS = '[Grant] Submit Grant Success',
  UPDATE_CUSTOM_REPORTING_PERIOD_FAILURE = '[Grant] Update Custom Reporting Period Failure',
  UPDATE_CUSTOM_REPORTING_PERIOD_REQUEST = '[Grant] Update Custom Reporting Period Request',
  UPDATE_CUSTOM_REPORTING_PERIOD_SUCCESS = '[Grant] Update Custom Reporting Period Success',
  UPDATE_FAVORITE_GRANTS_FAILURE = '[Grant] Update Favorite Grants Failure',
  UPDATE_FAVORITE_GRANTS_REQUEST = '[Grant] Update Favorite Grants Request',
  UPDATE_FAVORITE_GRANTS_SUCCESS = '[Grant] Update Favorite Grants Success',
  UPDATE_NUMBER_OF_SITES_FAILURE = '[Grant] Update Number Of Sites Failure',
  UPDATE_NUMBER_OF_SITES_REQUEST = '[Grant] Update Number Of Sites Request',
  UPDATE_NUMBER_OF_SITES_SUCCESS = '[Grant] Update Number Of Sites Success',
  UPDATE_SUBMISSION_COMMENT_FAILURE = '[Grant] Update Submission Comment Failure',
  UPDATE_SUBMISSION_COMMENT_REQUEST = '[Grant] Update Submission Comment Request',
  UPDATE_SUBMISSION_COMMENT_SUCCESS = '[Grant] Update Submission Comment Success',
}

export const acceptFileUpload = createAction(
  GrantActionTypes.ACCEPT_FILE_UPLOAD_REQUEST,
  props<{ grantId: number; userAccepted: boolean }>(),
);
export const acceptFileUploadFailure = createAction(
  GrantActionTypes.ACCEPT_FILE_UPLOAD_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const acceptFileUploadSuccess = createAction(
  GrantActionTypes.ACCEPT_FILE_UPLOAD_SUCCESS,
  props<{ response: ApiResponse }>(),
);

export const acceptGrantSubmission = createAction(
  GrantActionTypes.ACCEPT_GRANT_SUBMISSION_REQUEST,
  props<{ grantId: number; payload: GrantSubmissionModifyPayload }>(),
);
export const acceptGrantSubmissionFailure = createAction(
  GrantActionTypes.ACCEPT_GRANT_SUBMISSION_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const acceptGrantSubmissionSuccess = createAction(
  GrantActionTypes.ACCEPT_GRANT_SUBMISSION_SUCCESS,
  props<{ response: ApiResponse }>(),
);

export const clearFileUploadData = createAction(
  GrantActionTypes.CLEAR_FILE_UPLOAD_DATA,
);

export const deleteCustomReportingPeriod = createAction(
  GrantActionTypes.DELETE_CUSTOM_REPORTING_PERIOD_REQUEST,
  props<{ reportingPayload: CustomReportingPeriodPayload }>(),
);
export const deleteCustomReportingPeriodFailure = createAction(
  GrantActionTypes.DELETE_CUSTOM_REPORTING_PERIOD_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const deleteCustomReportingPeriodSuccess = createAction(
  GrantActionTypes.DELETE_CUSTOM_REPORTING_PERIOD_SUCCESS,
  props<{ grantId: number }>(),
);

export const deleteSubmissionComment = createAction(
  GrantActionTypes.DELETE_SUBMISSION_COMMENT_REQUEST,
  props<{ grantId: number; commentId: number }>(),
);
export const deleteSubmissionCommentFailure = createAction(
  GrantActionTypes.DELETE_SUBMISSION_COMMENT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const deleteSubmissionCommentSuccess = createAction(
  GrantActionTypes.DELETE_SUBMISSION_COMMENT_SUCCESS,
);

export const fileUploadTask = createAction(
  GrantActionTypes.FILE_UPLOAD_TASK_REQUEST,
  props<{ grantId: number; payload: FileUploadTaskPayload }>(),
);
export const fileUploadTaskFailure = createAction(
  GrantActionTypes.FILE_UPLOAD_TASK_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const fileUploadTaskSuccess = createAction(
  GrantActionTypes.FILE_UPLOAD_TASK_SUCCESS,
  props<{ response: FileUploadTaskApiResponse }>(),
);

export const getCustomReportingPeriod = createAction(
  GrantActionTypes.GET_CUSTOM_REPORTING_PERIOD_REQUEST,
  props<{ grantId: number }>(),
);
export const getCustomReportingPeriodFailure = createAction(
  GrantActionTypes.GET_CUSTOM_REPORTING_PERIOD_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getCustomReportingPeriodSuccess = createAction(
  GrantActionTypes.GET_CUSTOM_REPORTING_PERIOD_SUCCESS,
  props<{ customReportingPeriod: CustomReportingPeriodApiResponse }>(),
);

export const getDataColumns = createAction(
  GrantActionTypes.GET_DATA_COLUMNS_REQUEST,
);
export const getDataColumnsFailure = createAction(
  GrantActionTypes.GET_DATA_COLUMNS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getDataColumnsSuccess = createAction(
  GrantActionTypes.GET_DATA_COLUMNS_SUCCESS,
  props<{ dataColumns: FparDataColumnsApiResponse }>(),
);

export const getFavoriteGrants = createAction(
  GrantActionTypes.GET_FAVORITE_GRANTS_REQUEST,
);
export const getFavoriteGrantsFailure = createAction(
  GrantActionTypes.GET_FAVORITE_GRANTS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getFavoriteGrantsSuccess = createAction(
  GrantActionTypes.GET_FAVORITE_GRANTS_SUCCESS,
  props<{ response: FavoriteGrantsApiResponse }>(),
);

export const getFileUploadResults = createAction(
  GrantActionTypes.GET_FILE_UPLOAD_RESULTS_REQUEST,
  props<{ grantId: number }>(),
);
export const getFileUploadResultsFailure = createAction(
  GrantActionTypes.GET_FILE_UPLOAD_RESULTS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getFileUploadResultsSuccess = createAction(
  GrantActionTypes.GET_FILE_UPLOAD_RESULTS_SUCCESS,
  props<{ response: FileUploadResultApiResponse }>(),
);

export const getGrant = createAction(
  GrantActionTypes.GET_GRANT_REQUEST,
  props<{ payload: GrantRequestPayload }>(),
);
export const getGrantFailure = createAction(
  GrantActionTypes.GET_GRANT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getGrantSuccess = createAction(
  GrantActionTypes.GET_GRANT_SUCCESS,
  props<{ grant: GrantApiResponse }>(),
);

export const getGrantDetails = createAction(
  GrantActionTypes.GET_GRANT_DETAILS_REQUEST,
  props<{ payload: GrantRequestPayload }>(),
);
export const getGrantDetailsFailure = createAction(
  GrantActionTypes.GET_GRANT_DETAILS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getGrantDetailsSuccess = createAction(
  GrantActionTypes.GET_GRANT_DETAILS_SUCCESS,
  props<{ grant: UserGrantApiResponse }>(),
);

export const getGrantDetailsSilent = createAction(
  GrantActionTypes.GET_GRANT_DETAILS_SILENT_REQUEST,
  props<{ payload: GrantRequestPayload }>(),
);
export const getGrantDetailsSilentFailure = createAction(
  GrantActionTypes.GET_GRANT_DETAILS_SILENT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getGrantDetailsSilentSuccess = createAction(
  GrantActionTypes.GET_GRANT_DETAILS_SILENT_SUCCESS,
  props<{ grant: UserGrantApiResponse }>(),
);

export const getGrantList = createAction(
  GrantActionTypes.GET_GRANT_LIST_REQUEST,
  props<{ active?: boolean }>(),
);
export const getGrantListFailure = createAction(
  GrantActionTypes.GET_GRANT_LIST_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getGrantListSuccess = createAction(
  GrantActionTypes.GET_GRANT_LIST_SUCCESS,
  props<{ grants: GrantListApiResponse }>(),
);

export const getGrantListByUser = createAction(
  GrantActionTypes.GET_GRANT_LIST_BY_USER_REQUEST,
  props<UserGrantSelection>(),
);
export const getGrantListByUserFailure = createAction(
  GrantActionTypes.GET_GRANT_LIST_BY_USER_FAILURE,
  props<ApiError>(),
);
export const getGrantListByUserSuccess = createAction(
  GrantActionTypes.GET_GRANT_LIST_BY_USER_SUCCESS,
  props<{ grantList: UserGrantApiResponse }>(),
);

export const getGrantSubmissionsList = createAction(
  GrantActionTypes.GET_GRANT_SUBMISSIONS_LIST_REQUEST,
);
export const getGrantSubmissionsListFailure = createAction(
  GrantActionTypes.GET_GRANT_SUBMISSIONS_LIST_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getGrantSubmissionsListSuccess = createAction(
  GrantActionTypes.GET_GRANT_SUBMISSIONS_LIST_SUCCESS,
  props<{ grantSubmissions: GrantSubmissionListApiResponse }>(),
);

export const getGrantSubmissionTableOverview = createAction(
  GrantActionTypes.GET_GRANT_SUBMISSION_TABLE_OVERVIEW_REQUEST,
  props<{ grantId: number }>(),
);
export const getGrantSubmissionTableOverviewFailure = createAction(
  GrantActionTypes.GET_GRANT_SUBMISSION_TABLE_OVERVIEW_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getGrantSubmissionTableOverviewSuccess = createAction(
  GrantActionTypes.GET_GRANT_SUBMISSION_TABLE_OVERVIEW_SUCCESS,
  props<{
    grantSubmissionTableOverview: GrantSubmissionsTableOverviewApiResponse;
  }>(),
);

export const getNumberOfSites = createAction(
  GrantActionTypes.GET_NUMBER_OF_SITES_REQUEST,
  props<{ grantId: number }>(),
);
export const getNumberOfSitesFailure = createAction(
  GrantActionTypes.GET_NUMBER_OF_SITES_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getNumberOfSitesSuccess = createAction(
  GrantActionTypes.GET_NUMBER_OF_SITES_SUCCESS,
  props<{ numberOfSites: GrantNumberOfSitesApiResponse }>(),
);

export const getSelectedGrant = createAction(
  GrantActionTypes.GET_SELECTED_GRANT_REQUEST,
);
export const getSelectedGrantFailure = createAction(
  GrantActionTypes.GET_SELECTED_GRANT_FAILURE,
  props<{ message: string }>(),
);
export const getSelectedGrantSuccess = createAction(
  GrantActionTypes.GET_SELECTED_GRANT_SUCCESS,
  props<{ selectedGrant: UserGrantSelection }>(),
);

export const getSubmissionComment = createAction(
  GrantActionTypes.GET_SUBMISSION_COMMENT_REQUEST,
  props<{ grantId: number; reportingPeriodId?: number }>(),
);
export const getSubmissionCommentFailure = createAction(
  GrantActionTypes.GET_SUBMISSION_COMMENT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getSubmissionCommentSuccess = createAction(
  GrantActionTypes.GET_SUBMISSION_COMMENT_SUCCESS,
  props<{ submissionComment: FparSubmissionCommentApiResponse }>(),
);

export const getSubrecipients = createAction(
  GrantActionTypes.GET_SUBRECIPIENTS_REQUEST,
  props<{ grantId: number }>(),
);
export const getSubrecipientsFailure = createAction(
  GrantActionTypes.GET_SUBRECIPIENTS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getSubrecipientsSuccess = createAction(
  GrantActionTypes.GET_SUBRECIPIENTS_SUCCESS,
  props<{ subrecipients: SubrecipientApiResponse }>(),
);

export const getTableOverview = createAction(
  GrantActionTypes.GET_TABLE_OVERVIEW_REQUEST,
  props<{ grantId: number }>(),
);
export const getTableOverviewFailure = createAction(
  GrantActionTypes.GET_TABLE_OVERVIEW_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const getTableOverviewSuccess = createAction(
  GrantActionTypes.GET_TABLE_OVERVIEW_SUCCESS,
  props<{ tableOverview: GrantSubmissionTableOverviewApiResponse }>(),
);

export const postFilesToS3Failure = createAction(
  GrantActionTypes.POST_FILES_TO_S3_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const postFilesToS3Success = createAction(
  GrantActionTypes.POST_FILES_TO_S3_SUCCESS,
  props<{ response: null[] }>(),
);

export const resetFileUploadStatus = createAction(
  GrantActionTypes.RESET_FILE_UPLOAD_STATUS_REQUEST,
);

export const returnGrantSubmission = createAction(
  GrantActionTypes.RETURN_GRANT_SUBMISSION_REQUEST,
  props<{ grantId: number; payload: GrantSubmissionModifyPayload }>(),
);
export const returnGrantSubmissionFailure = createAction(
  GrantActionTypes.RETURN_GRANT_SUBMISSION_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const returnGrantSubmissionSuccess = createAction(
  GrantActionTypes.RETURN_GRANT_SUBMISSION_SUCCESS,
  props<{ response: ApiResponse }>(),
);

export const setCombinedUpload = createAction(
  GrantActionTypes.SET_COMBINED_UPLOAD_REQUEST,
  props<{ upload: GrantFileUploadData }>(),
);

export const setEncounterUpload = createAction(
  GrantActionTypes.SET_ENCOUNTER_UPLOAD_REQUEST,
  props<{ upload: GrantFileUploadData }>(),
);

export const setFileUploadStatus = createAction(
  GrantActionTypes.SET_FILE_UPLOAD_STATUS_REQUEST,
  props<{ status: GrantFileUploadStatus }>(),
);

export const setLabsUpload = createAction(
  GrantActionTypes.SET_LABS_UPLOAD_REQUEST,
  props<{ upload: GrantFileUploadData }>(),
);

export const setSelectedGrant = createAction(
  GrantActionTypes.SET_SELECTED_GRANT_REQUEST,
  props<UserGrantSelection>(),
);
export const setSelectedGrantFailure = createAction(
  GrantActionTypes.SET_SELECTED_GRANT_FAILURE,
  props<ApiError>(),
);
export const setSelectedGrantSuccess = createAction(
  GrantActionTypes.SET_SELECTED_GRANT_SUCCESS,
  props<{ selectedGrant: UserGrantSelection }>(),
);

export const submitGrant = createAction(
  GrantActionTypes.SUBMIT_GRANT_REQUEST,
  props<{ grantId: number }>(),
);
export const submitGrantFailure = createAction(
  GrantActionTypes.SUBMIT_GRANT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const submitGrantSuccess = createAction(
  GrantActionTypes.SUBMIT_GRANT_SUCCESS,
);

export const updateCustomReportingPeriod = createAction(
  GrantActionTypes.UPDATE_CUSTOM_REPORTING_PERIOD_REQUEST,
  props<{ reportingPayload: CustomReportingPeriodPayload }>(),
);
export const updateCustomReportingPeriodFailure = createAction(
  GrantActionTypes.UPDATE_CUSTOM_REPORTING_PERIOD_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateCustomReportingPeriodSuccess = createAction(
  GrantActionTypes.UPDATE_CUSTOM_REPORTING_PERIOD_SUCCESS,
);

export const updateFavoriteGrants = createAction(
  GrantActionTypes.UPDATE_FAVORITE_GRANTS_REQUEST,
  props<{ payload: FavoriteGrantsPayload }>(),
);
export const updateFavoriteGrantsFailure = createAction(
  GrantActionTypes.UPDATE_FAVORITE_GRANTS_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateFavoriteGrantsSuccess = createAction(
  GrantActionTypes.UPDATE_FAVORITE_GRANTS_SUCCESS,
  props<{ favoriteGrants: number[] }>(),
);

export const updateNumberOfSites = createAction(
  GrantActionTypes.UPDATE_NUMBER_OF_SITES_REQUEST,
  props<{ payload: GrantNumberOfSites }>(),
);
export const updateNumberOfSitesFailure = createAction(
  GrantActionTypes.UPDATE_NUMBER_OF_SITES_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateNumberOfSitesSuccess = createAction(
  GrantActionTypes.UPDATE_NUMBER_OF_SITES_SUCCESS,
  props<{ grantId: number }>(),
);
export const updateSubmissionComment = createAction(
  GrantActionTypes.UPDATE_SUBMISSION_COMMENT_REQUEST,
  props<{ grantId: number; comment: string }>(),
);
export const updateSubmissionCommentFailure = createAction(
  GrantActionTypes.UPDATE_SUBMISSION_COMMENT_FAILURE,
  props<{ error: HttpErrorResponse; message: string }>(),
);
export const updateSubmissionCommentSuccess = createAction(
  GrantActionTypes.UPDATE_SUBMISSION_COMMENT_SUCCESS,
);
