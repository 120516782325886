import { ApiResponse } from '@core/models';

export interface GrantCoverSheetAdminProjectDirector {
  grantId?: number;
  grAdminStAddr: string;
  grAdminCity: string;
  grAdminState: string;
  grAdminZipCode: string;
  grProjDirFirst: string;
  grProjDirLast: string;
  grProjDirMi: string;
  grProjDirSuffix: string;
  grProjDirTitle: string;
  grProjDirStAddr: string;
  grProjDirCity: string;
  grProjDirState: string;
  grProjDirZipCode: string;
  grProjDirPhoneNum: string;
  grProjDirFaxNum: string;
  grProjDirEmail: string;
}

export type GrantCoverSheetAdminProjectDirectorApiResponse = ApiResponse<
  GrantCoverSheetAdminProjectDirector[]
>;
