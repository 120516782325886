/* eslint-disable @typescript-eslint/naming-convention */
import { ApiResponse } from './api-response.interface';
import { Grant } from './grant.interface';

export interface UserGrantItem extends Grant {
  subrecipientId?: number;
  subrecipientName?: string;
  roleName: string;
}

export type UserGrantSelection = Partial<
  Pick<UserGrantItem, 'grantId' | 'subrecipientId'>
>;

export type UserGrantSelectionApiResponse = ApiResponse<UserGrantSelection>;
export type UserGrantApiResponse = ApiResponse<UserGrantItem[]>;
