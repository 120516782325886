import { ApiResponse } from './api-response.interface';

export interface CldbHistoryOfChangesEntry {
  description: string;
  editedBy: string;
  date: Date;
}

export type HistoryOfChangesApiResponse = ApiResponse<
  CldbHistoryOfChangesEntry[]
>;
