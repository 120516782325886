export interface ServicesPayload {
  providesServices: boolean;
  services: ClinicService[];
}

export interface ClinicService {
  serviceId: number;
  value: boolean;
}

export interface ServicesCategory {
  serviceCategory: string;
  services: SiteService[];
}

export interface SiteService {
  id: number;
  service: string;
}

export type ServicesOffered = ServicesCategory[];
