import { ApiResponse } from '@core/models';

export interface GrantCoverSheetMetaData {
  email: string;
  nameFirst: string;
  nameLast: string;
  roleName: string;
  dateModified: Date;
}

export type GrantCoverSheetMetaDataApiResponse = ApiResponse<
  GrantCoverSheetMetaData[]
>;
