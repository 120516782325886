import { ApiResponse } from './api-response.interface';

export interface User {
  accountReason: string;
  cognitoId: string;
  email: string;
  grantId: number;
  grFparNumber: string;
  granteeName?: string;
  grState?: string;
  nameFirst: string;
  nameLast: string;
  roleName: string;
  status: string;
  subrecipientId?: number;
}

export interface CreateUserPayload {
  accountReason: string;
  email: string;
  grantId?: number;
  nameFirst: string;
  nameLast: string;
  roleName: string;
  subrecipientId?: number;
}

export interface UpdateUserPayload {
  cognitoId: string;
  email: string;
  grantId?: number;
  nameFirst?: string;
  nameLast?: string;
}

export interface VerifySoftwareTokenParams {
  AccessToken: string;
  UserCode: string;
  FriendlyDeviceName: string;
}

export type ReactivateUserPayload = Pick<User, 'cognitoId'>;
export type RemoveUserPayload = Pick<User, 'cognitoId' | 'grantId'>;
export type ResetUserMFAPayload = Pick<User, 'cognitoId'>;

export type UsersApiResponse = ApiResponse<User[]>;
export type UserApiResponse = ApiResponse<User>;
export type UpdateUserApiResponse = ApiResponse<[]>;
