import { ApiResponse } from '@core/models';

export interface CustomReportingPeriod {
  customReportingPeriodId: number;
  grantId: number;
  reportingPeriodId: number;
  reportingPeriodStart?: Date;
  reportingPeriodEnd?: Date;
}

export type CustomReportingPeriodPayload = Omit<
  CustomReportingPeriod,
  'customReportingPeriodId' | 'reportingPeriodId'
>;

export type CustomReportingPeriodApiResponse = ApiResponse<
  CustomReportingPeriod[]
>;
