export interface StepConfiguration {
  description: string;
  label: string;
  path: string;
  completed?: boolean;
}

export interface CurrentStep {
  currentStepIndex: number;
  maxStepIndex;
}
