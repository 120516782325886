import * as FparTableActions from './actions';
import * as FparTableEffects from './effects';
import * as FparTableReducers from './reducer';
import * as FparTableSelectors from './selectors';
import * as FparTableState from './state';

export * from './fpar-table.module';
export {
  FparTableActions,
  FparTableEffects,
  FparTableReducers,
  FparTableSelectors,
  FparTableState,
};
