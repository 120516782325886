import { ApiResponse } from './api-response.interface';

export interface FparTable {
  columns: FparTableColumnDefinition[];
  comments?: FparComment[];
  issues: FparIssueGroup[];
  rows: FparTableRow[];
  tableNumber: number;
  tableName: string;
  tableTitle: string;
}

export interface FparTableColumnDefinition {
  columnNumber: number;
  column: string;
  headerName: string;
  isId: boolean;
  isLabel: boolean;
  isTotal: boolean;
  hideColumnTotal: boolean;
}

export interface FparTablePayload {
  table: FparTableRow[];
  other?: FparTableOtherRow[];
}

export interface FparTableRowBase {
  [key: string]: boolean | number | string | FparTableSubTotal;
  rowId: string;
}

export interface FparTableRow extends FparTableRowBase {
  idLabel?: string;
  isOtherSpecify?: boolean;
  rowLabel?: string;
  total?: FparTableSubTotal;
}

export interface FparTableOtherRow extends FparTableRowBase {
  text: string;
}

export interface FparTableSubTotal {
  [key: string]: string[][];
}

export interface FparComment {
  addedDate?: Date;
  author: string;
  comment: string;
  currentUser: boolean;
  id: number;
  parentCommentId?: number;
  replies?: FparIssueComment[];
  role: string;
  updatedDate?: Date;
}

export interface FparIssueComment extends FparComment {
  checkId: string;
}

export interface FparSubmissionComment {
  comment: string;
  commentId: number;
  lastUpdated: Date;
  nameFirst: string;
  nameLast: string;
  roleName: string;
}

export interface FparSubmissionCommentPayload {
  comment: string;
  grantId: number;
}

export interface FparTableIssue {
  checkId: string;
  displayMessage: string;
  newIssue: boolean;
  onHold: boolean;
  issueId: number;
  resolved: boolean;
  tableCellIssues: FparTableCellIssue[];
}

export interface FparTableCellIssue {
  column: string;
  columnHeader: string;
  columnId: string;
  displayMessage: string;
  row: string;
  rowId: string;
}

export interface FparIssueGroup {
  label: string;
  issueCount: number;
  list: FparIssueGroup[] | FparTableIssue[];
}

export type FparTableCommentPayload = Pick<FparComment, 'id' | 'comment'>;
export type FparTableIssuePayload = Pick<FparTableIssue, 'resolved'>;
export interface FparTableIssueCommentPayload {
  id?: number;
  comment: string;
  parentCommentId?: number;
}

export interface FparTableOverview {
  issueCount: number;
  submissionStatus: string;
  tableId: number;
  tableName: string;
  tableTitle: string;
}

export type FparSubmissionCommentApiResponse =
  ApiResponse<FparSubmissionComment>;
export type FparTableApiResponse = ApiResponse<FparTable>;
export type FparTableCommentApiResponse = ApiResponse<FparComment[]>;
export type FparTableIssueApiResponse = FparTableApiResponse;
export type FparTableIssueCommentApiResponse = ApiResponse<FparIssueComment[]>;
export interface FparTableIssueCommentActionResponse {
  apiResponse: FparTableIssueCommentApiResponse;
  checkId: string;
}

export interface IssueOnHoldPanelData {
  checkId: string;
  grantId: number;
  issue: FparTableIssue;
  tableId: number;
}
