import { FparTable, FparSubmissionComment } from '@core/models';

export interface FparDataSummary {
  granteeName: string;
  grFparNumber: string;
  submittedDate: string;
  grantId: number;
  fparTables: FparTable[];
  reportingPeriodName: string;
  reportingPeriodId: number;
  submissionComment: FparSubmissionComment;
}
