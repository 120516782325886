import { ApiResponse } from '.';

export interface FparDataColumns {
  encounters: FparDataColumn[];
  labs: FparDataColumn[];
  combined: FparDataColumn[];
}

export interface FparDataColumn {
  columnId: number;
  dataElement: string;
  fileType: string;
  missingAllowed: boolean;
  systemColumnDesc: string;
  systemColumnName: string;
}

export type FparDataColumnsApiResponse = ApiResponse<FparDataColumns>;
