import { ApiResponse } from './api-response.interface';

export interface KeyDate {
  description: string;
  id: number;
  keyDate: Date;
}

export type KeyDatePayload = Omit<KeyDate, 'id'>;
export type KeyDatesApiResponse = ApiResponse<KeyDate[]>;
