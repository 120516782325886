import { ApiResponse } from './api-response.interface';

export interface FileValidationIssue {
  type: string;
  messages: string[];
}

export interface FileUploadResult {
  completed: boolean;
  endDate: Date;
  errors: FileValidationIssue[];
  startDate: Date;
  userAccepted: boolean;
  warnings: FileValidationIssue[];
}

export type FileUploadResultApiResponse = ApiResponse<FileUploadResult[]>;
