import * as TableArchiveActions from './actions';
import * as TableArchiveEffects from './effects';
import * as TableArchiveReducer from './reducer';
import * as TableArchiveSelectors from './selectors';
import * as TableArchiveState from './state';

export * from './table-archive.module';
export {
  TableArchiveActions,
  TableArchiveEffects,
  TableArchiveReducer,
  TableArchiveSelectors,
  TableArchiveState,
};
