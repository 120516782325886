import { ApiResponse } from './api-response.interface';

export interface OverviewReport {
  accepted: number;
  alternateApproach: number;
  dataQualityReview: number;
  date: Date;
  grantWithUploads: number;
  inProgress: number;
  notStarted: number;
  preferredApproach: number;
  readyForOpaReview: number;
  reportingPeriodId: number;
  reportingPeriodYear: number;
  returnedForRemediation: number;
  totalGrants: number;
  totalUploads: number;
}

export interface RemovedUserReport {
  cognitoId: string;
  date: Date;
  email: string;
  firstName: string;
  grant: string;
  lastName: string;
  removalDate: Date;
  role: string;
}

export type OverviewReportApiResponse = ApiResponse<OverviewReport[]>;
export type RemovedUserReportApiResponse = ApiResponse<RemovedUserReport[]>;
