import { Resource } from '@app/shared/enums';
import { ApiResponse } from './api-response.interface';

export type ResourceAccessObject = {
  [R in Resource]?: boolean;
};

export interface ResourceAccessPayload {
  grantId: string;
  subrecipientId: string;
}

export type ResourceAccessApiResponse = ApiResponse<string[]>;
