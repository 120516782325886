import { Resource } from '@shared/enums';

export interface AppMenuList {
  items: AppMenuItem[];
  title: string;
}

export interface AppMenuItem {
  disabled?: boolean;
  icon: string;
  id: string;
  label: string;
  resourceAccess?: Resource[];
  route: string;
}
