import { FparTableColumn } from '@core/models';

export interface FparTableOtherConfig {
  columns: string[];
  startAfter: number | string;
  max: number;
}

export type FparDecimalConfig = Pick<FparTableColumn, 'column'> & {
  decimals: number;
};

export interface FparTableConfig {
  columns?: FparTableColumn[];
  decimals?: FparDecimalConfig[];
  description: string;
  disabled?: boolean;
  hideTotalRow?: boolean;
  isCurrency?: boolean;
  maxInputSize?: number;
  other?: FparTableOtherConfig;
  tableId: number;
  totalRowLabel?: string;
}
