import {
  CardDataSource,
  ChartType,
  GroupedBarChartCategory,
  StatType,
} from '@app/shared/enums';
import { ApiResponse } from './api-response.interface';
import { ReportingPeriodState } from './reporting-period-state.interface';

export interface BaseSubgroupData {
  subgroup: string;
  value: number;
}
export interface Card {
  benchmarkId: number;
  benchmarkName: string;
  benchmarkValue: number;
  cardId: number;
  cardTitle: string;
  dataSource: CardDataSource;
  detailsAvailable: boolean;
  measureValue: number;
  opaPriority: boolean;
  statType: StatType;
}

export interface CardDetails extends Card {
  cardGroup: number;
  cardChartType: ChartType;
  cardGroupsAvailable: CardGroup[];
  groupName: string;
  subgroupValues: SubgroupValue[];
}

export interface CardGroup {
  groupId: number;
  groupName: string;
}

export interface CardDetailsParameters {
  benchmarkId?: number;
  grantId?: number;
  groupId?: number;
  reportingPeriodId?: number;
}

export interface CardsParameters {
  benchmarkId: number;
  facilityIds?: number[];
  grantId?: number;
  reportingPeriodId?: number;
}

export interface DashboardBenchmark {
  benchmarkId: number;
  benchmarkName: string;
  measureId: number;
  reportingPeriodId: number;
}
export interface DashboardCardData {
  cards: Card[];
  reportingPeriodState: ReportingPeriodState;
}

export interface DashboardFilterData {
  benchmarkId: number;
  benchmarkName: string;
  cardId: number;
  cardTitle: string;
  detailsAvailable: boolean;
  grantId: number;
  granteeName: string;
  grFparNumber: string;
  grState: string;
  reportingPeriodId: number;
  reportingPeriodName: string;
}

export interface SubgroupValue extends BaseSubgroupData {
  benchmarkValue: number;
}

export interface SubgroupTableData {
  [key: string]: number | string; // this covers the benchmarkValue name and subgroup name for table and export purposes
  'Grantee Value': number | string;
}

export interface GroupedBarChartData extends BaseSubgroupData {
  category: GroupedBarChartCategory;
}

export type DashboardBenchmarksApiResponse = ApiResponse<DashboardBenchmark[]>;
export type DashboardCardsApiResponse = ApiResponse<DashboardCardData>;
export type DashboardCardDetailsApiResponse = ApiResponse<CardDetails[]>;
export type DashboardFilterDataApiResponse = ApiResponse<DashboardFilterData[]>;
export type DashboardReportingPeriodsApiResponse = ApiResponse<number[]>;
