import { GrantSubmissionStatus } from '@app/shared/enums';
import { ReportingPeriodState } from '@core/models';
import { ApiResponse } from './api-response.interface';

export interface GrantSubmissionReviewStatus {
  reviewStatus: GrantSubmissionStatus;
  submissionStatus: GrantSubmissionStatus;
  reportingPeriod: ReportingPeriodState;
}

export interface GrantSubmissionStatusDetails {
  icon: string;
  class: string;
}

export type ChangeGrantSubmissionStatus = Pick<
  GrantSubmissionReviewStatus,
  'submissionStatus'
>;

export type GrantSubmissionReviewStatusApiResponse = ApiResponse<
  GrantSubmissionReviewStatus[]
>;
