import { ApiResponse } from './api-response.interface';

export interface ReportingPeriod {
  active: boolean;
  dataAvailable: boolean;
  id: number;
  previousReportingPeriodId: null | number;
  reportingPeriodName: string;
  startDate: Date;
  stopDate: Date;
  submissionOpen: boolean;
  validationOpen: boolean;
}

export type ReportingPeriodPayload = Omit<ReportingPeriod, 'id'>;
export type ReportingPeriodApiResponse = ApiResponse<ReportingPeriod[]>;
