import { TableSubmissionStatus } from '@app/shared/enums';
import { ApiResponse } from '@core/models';

export interface GrantNumberOfSites {
  grantId: number;
  titlexSubrecipients: number | string;
  titlexSites: number | string;
  differentFromApp: boolean;
  differentFromAppReason: string;
  telehealthClinics: number | string;
  status?: TableSubmissionStatus;
}

export type GrantNumberOfSitesApiResponse = ApiResponse<GrantNumberOfSites[]>;
